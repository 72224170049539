import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import BackupHub from '../../components/RestoreHub';
import { Accordion, Alert, Spinner } from 'react-bootstrap';
import { Notification } from '../../components/Notification';
import {
	useAuthState,
	useAuthDispatch,
	handleLogoutUser,
} from '../../context/AuthContext';

export default function RestorePage() {
	const [backupData, setBackupData] = useState([]);
	const [backupsLoading, setBackupsLoading] = useState(false);
	const user = useAuthState();
	const dispatch = useAuthDispatch();

	useEffect(() => {
		if (!user.loggedIn) return;
		fetchBackupData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchBackupData = () => {
		setBackupsLoading(true);

		Axios.post('fetch/vault-records')
			.then((res) => {
				if (res.status >= 200 && res.status < 300) {
					const filteredData = res.data.data.filter((hub) => {
						return Object.keys(hub.codeBlocks).length > 0;
					});
					setBackupData(filteredData);
				} else setBackupData([]);
				setBackupsLoading(false);
			})
			.catch((e) => {
				setBackupData([]);
				setBackupsLoading(false);
				if (e.response.status === 401) {
					handleLogoutUser(dispatch);
					return;
				} else {
					Notification('danger', e.response.data.message);
				}
			});
	};

	return (
		<div>
			<div className="newContainer">
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<p style={{ margin: '0' }}>
						Click on a Hub to view available Custom Code backups and
						restore them to that Hub.
					</p>
					{backupsLoading && (
						<Spinner
							style={{ marginLeft: '10px' }}
							animation="border"
							variant="danger"
							size="100px"
						/>
					)}
				</div>
				<hr style={{ margin: '25px 0px 25px 0px' }} />{' '}
				<h5>Your Hubs' Code Backups</h5>
				{backupData.length > 0 ? (
					<Accordion className="backupHubs" defaultActiveKey="0">
						{backupData.length > 0
							? backupData.map((hubData, i) => {
									return (
										<BackupHub
											hubData={hubData}
											key={hubData.hubId}
										></BackupHub>
									);
							  })
							: ''}
					</Accordion>
				) : backupsLoading ? (
					<></>
				) : (
					<Alert variant="warning">
						No Custom Code backups found, please visit the
						<a href="/"> Backup</a> page to enable automatic
						backups.
					</Alert>
				)}
			</div>
		</div>
	);
}
