import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Notification } from '../../components/Notification';
import BackupTimer from '../../components/BackupTimer';
import HubTile from '../../components/HubTile';
import { handleLogoutUser, useAuthDispatch } from '../../context/AuthContext';
import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import momentTimezone from 'moment-timezone';

export default function BackupPage() {
	Axios.defaults.withCredentials = true;
	const [showTimer, setShowTimer] = useState(false);
	const [backupsLoading, setBackupsLoading] = useState(false);
	const [codeBlockResult, setCodeBlockResult] = useState([]);
	const dispatch = useAuthDispatch();

	const serverRunTime = moment(new Date()).tz('America/Toronto');

	const workerRunTime = moment(serverRunTime).set({
		hour: 23,
		minute: 45,
		second: 0,
		millisecond: 0,
	});

	const backupTime = moment(workerRunTime).local().format('lll');

	useEffect(() => {
		fetchHubs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setShowTimer(anyVaultsExists());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [codeBlockResult]);

	const anyVaultsExists = () => {
		if (!codeBlockResult) return false;
		const exists = codeBlockResult.some((hub) => hub.vaultExists);
		return exists;
	};

	const fetchHubs = () => {
		setBackupsLoading(true);

		Axios.post('fetch/hubs')
			.then((res) => {
				if (res.status >= 200 && res.status < 300) {
					if (res.data.hubCode.length <= 0) {
						Notification('warning', 'No hubs found');
					}
					setCodeBlockResult(res.data.hubCode);
				}
			})
			.catch((e) => {
				if (e.response.status === 401) {
					handleLogoutUser(dispatch);
					return;
				} else {
					Notification('danger', e.response.data.message);
				}
				if (e.response) {
					console.log(e.response);
				} else if (e.request) {
					console.log('Client never recieved request: ' + e.request);
				} else {
					console.log('Error:' + e.message);
				}
			})
			.finally(() => setBackupsLoading(false));
	};

	return (
		<>
			<div className="newContainer">
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<p style={{ margin: '0' }}>
						Turn on or pause automatic Custom Code backups for your
						Hubs.
					</p>
					{backupsLoading && (
						<Spinner
							style={{
								marginLeft: '10px',
								marginBottom: '10px',
							}}
							animation="border"
							variant="danger"
							size="100px"
						/>
					)}
				</div>
				<hr style={{ margin: '25px 0px 25px 0px' }} />
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<h5 style={{ margin: '0px' }}>Your Hubs</h5>

					{showTimer ? (
						<OverlayTrigger
							delay="1000"
							overlay={
								<Tooltip>
									All of your enabled Hubs will be
									automatically backed-up on a recurring
									basis.
								</Tooltip>
							}
						>
							<div
								style={{
									marginLeft: 'auto',
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<div
									style={{
										paddingRight: '10px',
									}}
								>
									<h6
										style={{
											fontWeight: 'normal',
											margin: '0px 5px 0px 0px',
										}}
									>
										Next Auto-Backup
									</h6>
									<h6
										style={{
											paddingTop: '5px',
											fontSize: '12px',
											fontWeight: '500',
											color: '#00000076',
											margin: '0px',
										}}
									>{`${backupTime}`}</h6>
								</div>

								<div>
									<BackupTimer />
								</div>
							</div>
						</OverlayTrigger>
					) : (
						<></>
					)}
				</div>

				<div
					style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}
				>
					{codeBlockResult.map((codeBlock, i) => (
						<HubTile
							key={i}
							id={codeBlock.hubId}
							name={codeBlock.hubName}
							url={codeBlock.hubURL}
							count={codeBlock.res.length}
							isVaulted={codeBlock.isVaulted}
							vaultExists={codeBlock.vaultExists}
							workerCheck={codeBlock.workerCheck}
							showTimer={setShowTimer}
						/>
					))}
				</div>
			</div>
		</>
	);
}
