import React, { useContext } from 'react';
import { Card, AccordionContext, useAccordionToggle } from 'react-bootstrap';
import { ExpandMore, ExpandLess } from '@material-ui/icons/';

export default function ContextAwareToggle({ children, eventKey, callback }) {
	const currentEventKey = useContext(AccordionContext);

	const decoratedOnClick = useAccordionToggle(
		eventKey,
		() => callback && callback(eventKey)
	);

	const isCurrentEventKey = currentEventKey === eventKey;

	return (
		<Card.Header
			className="hub-accordion-header"
			onClick={decoratedOnClick}
			style={{ display: 'flex' }}
		>
			{isCurrentEventKey ? (
				<ExpandLess></ExpandLess>
			) : (
				<ExpandMore></ExpandMore>
			)}

			{children}
		</Card.Header>
	);
}
