import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import dateFormat from 'dateformat';
import {
	FormControl,
	InputGroup,
	Button,
	Modal,
	Spinner,
	Alert,
} from 'react-bootstrap';
import { Warning, CheckCircle, OpenInNew } from '@material-ui/icons/';
import { Notification } from './Notification';
import { handleLogoutUser, useAuthDispatch } from '../context/AuthContext';

const RestoreModal = ({
	displayModal,
	setDisplayModal,
	selectedBackup,
	codeBlockName,
	restoredDate,
}) => {
	const placeholder = `Type ${codeBlockName}`;
	const [inputValue, setInputValue] = useState('');
	const [buttonText, setButtonText] = useState('Restore');
	const [inlineTitle, setInlineTitle] = useState(
		'Confirm Custom Code Restore'
	);
	const [disabledButton, setDisabledButton] = useState(true);
	const [restoring, setRestoring] = useState(false);
	const [showConfirmCodeBlock, setShowConfirmCodeBlock] = useState(true);
	const [showEnableCodeBlock, setShowEnableCodeBlock] = useState(false);
	const dispatch = useAuthDispatch();

	const handleClose = () => setDisplayModal(false);

	const customCodeURL = `https://app.uberflip.com/hubs/custom_code/${selectedBackup.hubId}`;

	const restoreBackup = (e) => {
		setButtonText('Restoring...');
		setRestoring(true);
		setDisabledButton(true);
		const payload = {
			selectedBackup,
			codeBlockName,
		};

		Axios.post('restore/backup', payload)
			.then((res) => {
				setRestoring(false);
				setDisabledButton(false);
				setShowConfirmCodeBlock(false);
				if (res.status >= 200 && res.status < 300) {
					setInlineTitle('Enable Custom Code');
					Notification('success', res.data.message);
					setShowEnableCodeBlock(true);
					restoredDate(res.data.restoredDate);
				}
			})
			.catch((e) => {
				setRestoring(false);
				setButtonText('Restore');

				if (e.response.status === 401) {
					handleLogoutUser(dispatch);
					return;
				} else {
					Notification('danger', e.response.data.message);
				}
				if (e.response) {
					console.log(e);
				} else if (e.request) {
					console.log('Client never recieved request: ' + e.request);
				} else {
					console.log('Error:' + e.message);
				}
			});
	};

	const updateCodeBlock = (e) => {
		let enabled = e.target.value;

		const payload = {
			selectedBackup,
			enabled,
			codeBlockName,
		};

		console.log(payload);

		Axios.post('update/code-block', payload)
			.then((res) => {
				if (res.status >= 200 && res.status < 300) {
					Notification('success', res.data.message);
					setDisplayModal(false);
					console.log(res.data);
				}
			})
			.catch((e) => {
				console.log('status:', e.response.status);
				if (e.response.status === 401) {
					handleLogoutUser(dispatch);
					return;
				} else {
					Notification('danger', e.response.data.message);
				}
				if (e.response) {
					console.log(e);
				} else if (e.request) {
					console.log('Client never recieved request: ' + e.request);
				} else {
					console.log('Error:' + e.message);
				}
			});
	};

	const warningNotification = (codeBlock) => {
		const message = `You did not enable ${codeBlock}. You can manually manage your Hub's Custom Code in the Uberflip app.`;
		Notification('warning', message);
	};

	return (
		<>
			<Modal show={displayModal} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{inlineTitle}</Modal.Title>
				</Modal.Header>

				{showConfirmCodeBlock ? (
					<>
						<Modal.Body>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<div style={{ marginRight: '10px' }}>
									<Warning
										fontSize="large"
										style={{ color: 'red' }}
									></Warning>
								</div>
								<div>
									Custom Code in your Hub will be replaced
									with the backup you have selected. You
									cannot undo this action.
								</div>
							</div>
							<div
								style={{
									fontSize: '12px',
									paddingTop: '20px',
									paddingLeft: '46px',
								}}
							>
								<div>
									Hub:
									<b style={{ paddingLeft: '4px' }}>
										{selectedBackup.hubName}
									</b>
								</div>
								<div>
									Custom Code Name:
									<b style={{ paddingLeft: '4px' }}>
										{codeBlockName}
									</b>
								</div>
								<div>
									Code Placement:
									<b style={{ paddingLeft: '4px' }}>
										{selectedBackup.placement.name}
									</b>
								</div>
								<div>
									Backup Date:
									<b style={{ paddingLeft: '4px' }}>
										{dateFormat(
											selectedBackup.modified,
											'mmm dd, yyyy, hh:MM TT'
										)}
									</b>
								</div>
							</div>
							<hr />
							<div
								style={{
									paddingBottom: '4px',
								}}
							>
								<div style={{ marginBottom: '10px' }}>
									Type <b>{codeBlockName}</b> in the field
									below to confirm.
								</div>

								<div className="input-group">
									<InputGroup size="sm">
										<FormControl
											aria-label="Default"
											aria-describedby="inputGroup-sizing-default"
											type="text"
											value={inputValue}
											placeholder={placeholder}
											onChange={(e) => {
												setInputValue(e.target.value);
												if (
													e.target.value ===
														codeBlockName ||
													e.target.value ===
														codeBlockName.replace(
															/  +/g,
															' '
														)
												) {
													setDisabledButton(false);
												} else {
													setDisabledButton(true);
												}
											}}
											required
										/>
									</InputGroup>
								</div>
							</div>
						</Modal.Body>

						<Modal.Footer>
							<Button
								className="btn"
								size="sm"
								variant="link"
								onClick={handleClose}
								style={{ minWidth: '80px' }}
							>
								Cancel
							</Button>

							<Button
								className="btn btn-restore-fill"
								size="sm"
								variant="danger"
								style={{
									backgroundColor: '#CE0058',
									minWidth: '80px',
								}}
								onClick={(e) => {
									restoreBackup(e);
								}}
								disabled={disabledButton}
							>
								{buttonText}
								{restoring ? (
									<Spinner
										as="span"
										animation="grow"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
								) : null}
							</Button>
						</Modal.Footer>
					</>
				) : null}

				{showEnableCodeBlock ? (
					<>
						<Modal.Body>
							<Alert variant="success">
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<div>
										<CheckCircle fontSize="medium" />
									</div>

									<div
										style={{
											fontSize: '12px',
											paddingLeft: '1.25rem',
										}}
									>
										<div>
											Hub:
											<b style={{ paddingLeft: '4px' }}>
												{selectedBackup.hubName}
											</b>
										</div>
										<div>
											Custom Code Name:
											<b style={{ paddingLeft: '4px' }}>
												{selectedBackup.name}
											</b>
										</div>
										<div>
											Code Placement:
											<b style={{ paddingLeft: '4px' }}>
												{selectedBackup.placement.name}
											</b>
										</div>
										<div>
											Backup Date:
											<b style={{ paddingLeft: '4px' }}>
												{dateFormat(
													selectedBackup.modified,
													'mmm dd, yyyy, hh:MM TT'
												)}
											</b>
										</div>
									</div>
								</div>
							</Alert>
							<div style={{ paddingTop: '12px' }}>
								Your code was successfully restored but{' '}
								<b>not enabled</b>.
							</div>
							<br />
							<div>
								<b>
									Would you like to enable this Custom Code
									block?
								</b>{' '}
							</div>
							<div
								style={{
									paddingTop: '25px',
									fontSize: '15px',
								}}
							>
								<b>IMPORTANT:</b> Enabling could affect your
								Hub's appearance and/or functionality. You can
								manually manage your Custom Code in the
								<a
									href={customCodeURL}
									rel="noopener noreferrer"
									target="_blank"
								>
									<div
										style={{
											display: 'inline-flex',
											justifyContent: 'center',
											color: '#212529',
											fontWeight: '600',
										}}
									>
										Uberflip app
										<OpenInNew
											style={{}}
											fontSize="small"
										></OpenInNew>
									</div>
								</a>{' '}
								.
							</div>
						</Modal.Body>

						<Modal.Footer>
							<Button
								className="btn"
								size="sm"
								variant="link"
								style={{
									minWidth: '80px',
								}}
								onClick={() => {
									handleClose();
									warningNotification(selectedBackup.name);
								}}
							>
								No, thanks
							</Button>
							<Button
								className="btn btn-restore-fill"
								value={true}
								size="sm"
								variant="danger"
								style={{
									minWidth: '80px',
									backgroundColor: '#ce0058',
								}}
								onClick={(e) => {
									updateCodeBlock(e);
								}}
							>
								Enable
							</Button>
						</Modal.Footer>
					</>
				) : (
					<></>
				)}
			</Modal>
		</>
	);
};

RestoreModal.propTypes = {
	displayModal: PropTypes.bool.isRequired,
	setDisplayModal: PropTypes.func.isRequired,
	selectedBackup: PropTypes.object,
	codeBlockName: PropTypes.string.isRequired,
	restoredDate: PropTypes.func.isRequired,
};

export default RestoreModal;
