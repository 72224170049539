import React, { useState } from 'react';
import moment from 'moment';
import RestoreModal from './RestoreModal';
import Axios from 'axios';
import { Button, Collapse, ListGroup } from 'react-bootstrap';
import { Code, SettingsBackupRestore } from '@material-ui/icons/';
import Select from 'react-select';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Notification } from './Notification';
import { handleLogoutUser, useAuthDispatch } from '../context/AuthContext';

export default function RestoreCodeBlock({ codeBlockData }) {
	const [selectedBackup, setSelectedBackup] = useState({});
	const [codeBackups, setCodeBackups] = useState(undefined);
	const [backupsLoading, setBackupsLoading] = useState(false);
	const [codeOpen, setCodeOpen] = useState(false);
	const [displayModal, setDisplayModal] = useState(false);
	const [options, setOptions] = useState([{}]);
	const dispatch = useAuthDispatch();

	const [lastRestored, setLastRestored] = useState(
		codeBlockData.lastRestored
	);
	Axios.defaults.withCredentials = true;

	const onSelectBackup = (e) => {
		const backup = codeBackups.filter(
			(backup) => backup.modified === e.value
		)[0];
		setSelectedBackup(backup);
	};

	const loadBackups = (e) => {
		if (codeBackups !== undefined) return;
		setBackupsLoading(true);

		const payload = {
			hubId: codeBlockData.hubId,
			fileId: codeBlockData.fileId,
		};

		Axios.post('fetch/codeblock-backups', payload)
			.then((res) => {
				setCodeBackups(res.data.data);
				setBackupsLoading(false);
				setOptions(res.data.data);

				let optionsArray = res.data.data.map((backup) => {
					return {
						value: backup.modified,
						label: moment(backup.modified)
							.local(true)
							.format('lll'),
					};
				});
				setOptions(optionsArray);
			})
			.catch((e) => {
				setCodeBackups([]);
				setBackupsLoading(false);
				let notificationLevel = 'danger';
				if (e.response.status === 404) {
					notificationLevel = 'warning';
				}
				if (e.response.status === 401) {
					handleLogoutUser(dispatch);
					return;
				} else {
					Notification(notificationLevel, e.response?.data.message);
				}
			});
	};

	return (
		<ListGroup.Item className="p-0">
			<div className="codeBlock">
				<div className="codeBlock-header pb-4">
					<div style={{ fontWeight: 'normal' }}>Custom Code Name</div>
					<h5 style={{ fontWeight: 'bold' }}>
						{codeBlockData.codeBlockName}
					</h5>
				</div>
				<div className="codeBlock-controls">
					<div className="codeBlock-controls-row">
						<div className="codeBlock-controls-label">
							Select Backup By Date
						</div>
					</div>

					<div className="codeBlock-controls-row">
						<Select
							isSearchable={false}
							onBlurResetsInput={false}
							onCloseResetsInput={false}
							labelKey="label"
							valueKey="value"
							autoLoad={false}
							isLoading={backupsLoading}
							options={options}
							onFocus={loadBackups}
							onChange={onSelectBackup}
							menuPortalTarget={document.body}
							styles={{
								menuPortal: (base) => ({
									...base,
									zIndex: 9999,
								}),
							}}
						></Select>
						<Button
							onClick={() => setCodeOpen(!codeOpen)}
							aria-controls="codeBlock-code"
							aria-expanded={codeOpen}
							size="sm"
							variant="secondary"
							hidden={selectedBackup?.content === undefined}
							style={{ marginLeft: '10px' }}
						>
							View Code{'   '}
							<Code />
						</Button>
						{selectedBackup?.content !== undefined &&
						lastRestored ? (
							<div
								style={{
									padding: '1.25rem',
									position: 'absolute',
									top: '0px',
									right: '0px',
									textAlign: 'right',
								}}
							>
								<div>
									<h6
										style={{
											fontWeight: 'normal',
											fontSize: '14px',
											marginBottom: '0px',
										}}
									>
										Last Restored
									</h6>
									<h6
										style={{
											paddingTop: '5px',
											fontSize: '11px',
											fontWeight: '600',
											color: '#00000076',
											margin: '0px',
										}}
									>{`${moment(lastRestored)
										.local()
										.format('lll')}`}</h6>
								</div>
							</div>
						) : (
							<></>
						)}
						<Button
							className="codeBlock-controls-restore ml-auto btn-restore-fill"
							size="sm"
							variant="danger"
							onClick={() => setDisplayModal(true)}
							hidden={selectedBackup?.content === undefined}
						>
							Restore{'  '}
							<SettingsBackupRestore />
						</Button>
					</div>
				</div>
			</div>
			<Collapse className="codeBlock-code-collapse" in={codeOpen}>
				<div id="codeBlock-code" style={{ backgroundColor: '#111111' }}>
					<hr style={{ marginTop: '0px' }}></hr>
					<div
						style={{
							color: '#dddddd',
							marginLeft: '20px',
							marginBottom: '15px',
						}}
					>
						Code Placement:
						<b style={{ paddingLeft: '4px' }}>
							{selectedBackup?.placement?.name}
						</b>
					</div>
					{codeOpen ? (
						<SyntaxHighlighter
							language="javascript"
							style={vscDarkPlus}
							showLineNumbers
							wrapLines
						>
							{selectedBackup?.content
								? selectedBackup.content
								: 'null'}
						</SyntaxHighlighter>
					) : (
						<></>
					)}
				</div>
			</Collapse>
			{displayModal ? (
				<RestoreModal
					displayModal={displayModal}
					setDisplayModal={setDisplayModal}
					codeBlockName={codeBlockData.codeBlockName}
					selectedBackup={selectedBackup}
					restoredDate={setLastRestored}
				/>
			) : (
				<></>
			)}
		</ListGroup.Item>
	);
}
