import React, { useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import '../styles/container.css';
import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import momentTimezone from 'moment-timezone';

const BackupTimer = () => {
	const [timerStroke, setTimerStroke] = useState(3);

	const renderTime = ({ remainingTime }) => {
		let text;
		let format;
		let time;

		// Backups are running
		if (remainingTime === 0) {
			setTimerStroke(0);
			text = 'Backups running...';
		} else if (remainingTime < 60) {
			// Time is in seconds
			setTimerStroke(3);
			time = remainingTime;
			format = 'sec';
		} else if (remainingTime > 3600) {
			// Time is in hours
			setTimerStroke(3);
			time = (remainingTime / 3600).toFixed(0);
			format = 'hrs';
		} else if (remainingTime >= 60 && remainingTime < 3600) {
			// Time is in minutes
			setTimerStroke(3);
			time = (remainingTime / 60).toFixed(0);
			format = 'min';
		}
		return (
			<>
				<div className="timer">
					<div className="timerValue">{time}</div>
					<div className="timerFormat">{format}</div>
				</div>
				<div className="timerText">{text}</div>
			</>
		);
	};

	const initialRemainingTime = () => {
		let localDate = moment(new Date());

		const serverRunTime = moment(new Date()).tz('America/Toronto');

		const workerTime = moment(serverRunTime).set({
			hour: 23,
			minute: 45,
			second: 0,
			millisecond: 0,
		});

		let diffSeconds = Math.abs(workerTime - localDate) * 0.001;

		return diffSeconds;
	};

	return (
		<>
			<CountdownCircleTimer
				isPlaying
				duration={86400}
				initialRemainingTime={initialRemainingTime()}
				onComplete={() => [true, 900000]}
				colors={[
					['#041B64', 0.5],
					['#CE0058', 0.5],
				]}
				strokeWidth={timerStroke}
				size={43}
			>
				{renderTime}
			</CountdownCircleTimer>
		</>
	);
};

export default BackupTimer;
