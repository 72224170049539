import Login from '../pages/Login/Login';
import BackupPage from '../pages/Backup/BackupPage';
import RestorePage from '../pages/Restore/RestorePage';

const routes = [
	{
		path: '/login',
		component: Login,
		isPrivate: false,
	},
	{
		path: '/restore',
		component: RestorePage,
		isPrivate: true,
	},
	{
		path: '/',
		component: BackupPage,
		isPrivate: true,
	},
];

export default routes;
