import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useAuthState, useAuthDispatch } from '../context/AuthContext';
import axios from 'axios';

function pendoInitialize(userId, accountId) {
	window.pendo.initialize({
		visitor: {
			id: userId,
		},
		account: {
			id: accountId,
		},
	});
}

const AppRoutes = ({ component: Component, path, isPrivate, ...rest }) => {
	const user = useAuthState();
	const dispatch = useAuthDispatch();

	useEffect(() => {
		const check = async () => {
			const redirectUrl = await axios({
				url: '/api/saml/saml-request',
				method: 'get',
			});
			const { loginUrl } = redirectUrl.data;
			window.location.replace(loginUrl);
		};

		const checkAuthStatus = async () => {
			const authStatus = await axios({ url: '/api/saml/auth-status' });
			const { authenticated, userId, accountId } = authStatus.data;
			if (!authenticated) {
				check();
			} else {
				pendoInitialize(userId, accountId);
				dispatch({ loggedIn: true });
			}
		};

		checkAuthStatus();
	}, [dispatch]);

	return (
		<Route
			path={path}
			render={(props) =>
				isPrivate && !Boolean(user.loggedIn) ? (
					<div>Not Authenticated, checking authentication</div>
				) : (
					<Component {...props} />
				)
			}
			{...rest}
		/>
	);
};

export default AppRoutes;
