import React from 'react';
import Axios from 'axios';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import { AuthProvider } from './context/AuthContext';
import NavigationBar from './pages/Navbar/NavigationBar';
import routes from './routes/routes';
import AppRoute from './routes/appRoutes';
import 'react-notifications-component/dist/theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/navbar.css';
import './styles/container.css';
Axios.defaults.withCredentials = true;

export default function App() {
	return (
		<AuthProvider>
			<ReactNotifications />
			<BrowserRouter>
				<NavigationBar />
				<Switch>
					{routes.map((route) => (
						<AppRoute
							key={route.path}
							path={route.path}
							component={route.component}
							isPrivate={route.isPrivate}
						/>
					))}
				</Switch>
			</BrowserRouter>
		</AuthProvider>
	);
}
