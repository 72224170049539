import React, { useCallback } from 'react';
import LoginForm from './LoginForm';

export default function Login({ location, history }) {
	const previousLocation =
		location?.state?.from?.pathname.length > 0
			? location.state.from.pathname
			: '/';

	const handleAuthSuccess = useCallback(() => {
		try {
			history.push(previousLocation);
		} catch (error) {
			history.push('/');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, previousLocation]);

	return <LoginForm handleAuthSuccess={handleAuthSuccess} />;
}
