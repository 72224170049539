import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Form, Button, Spinner } from 'react-bootstrap';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useAuthDispatch, handleLogoutUser } from '../../context/AuthContext';
import logo from '../../images/logo.png';

function pendoInitialize(userId, accountId) {
	window.pendo.initialize({
		visitor: {
			id: userId,
		},
		account: {
			id: accountId,
		},
	});
}

export default function LoginForm({ handleAuthSuccess }) {
	const [APIKey, setAPIKey] = useState('');
	const [APISecret, setAPISecret] = useState('');
	const [statusLoading, setStatusLoading] = useState(false);
	const [authenticating, setAuthenticating] = useState(false);
	const [invalid, setInvalid] = useState(false);
	const dispatch = useAuthDispatch();

	useEffect(() => {
		setStatusLoading(true);
		Axios.post('auth/status')
			.then((res) => {
				if (res.data) {
					dispatch({ loggedIn: res.data.loggedIn });
					handleAuthSuccess();
				}
			})
			.catch((e) => {
				handleLogoutUser(dispatch);
				console.log('error: ', e);
			})
			.finally(() => {
				setStatusLoading(false);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, handleAuthSuccess]);

	const handleLogin = (e) => {
		e.preventDefault();
		setAuthenticating(true);

		Axios.post('auth/login', { authCreds: { APIKey, APISecret } })
			.then((res) => {
				if (res.status === 200) {
					console.log(res);
					pendoInitialize(res.data.userId, res.data.accountId);
					dispatch(res.data);
					localStorage.setItem(
						'currentUser',
						JSON.stringify(res.data)
					);
					handleAuthSuccess();
				}
			})
			.catch((e) => {
				console.log(e.response.data.message);
				handleLogoutUser(dispatch);
				if (e.response.status === 401) {
					setInvalid(true);
				}
			})
			.finally(() => setAuthenticating(false));
	};

	if (statusLoading) return <></>;

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					padding: '45px',
				}}
			>
				<img
					style={{ marginBottom: '9px', borderRadius: '25px' }}
					id="image"
					src={logo}
					width="50"
					height="50"
					alt="logo"
				/>
				<h5
					style={{
						fontSize: '18px',
						letterSpacing: '0.1em',
						padding: '30px 0px',
					}}
				>
					SIGN IN TO CODE SAVER
				</h5>
				<Form
					className="mb-3"
					style={{
						maxWidth: '600px',
						padding: '45px',
						boxShadow:
							'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
					}}
				>
					<Form.Group style={{ width: '400px' }}>
						<Form.Label>API Key</Form.Label>
						<Form.Control
							id="apiKEY"
							value={APIKey}
							onChange={(e) => setAPIKey(e.target.value)}
							isInvalid={invalid}
							type="password"
							disabled={authenticating}
						/>
					</Form.Group>
					<Form.Group style={{ width: '400px' }}>
						<Form.Label>API Secret</Form.Label>
						<Form.Control
							id="apiSecret"
							value={APISecret}
							onChange={(e) => setAPISecret(e.target.value)}
							isInvalid={invalid}
							type="password"
							disabled={authenticating}
						/>
					</Form.Group>
					<Form.Group style={{ width: '400px' }}>
						<Form.Control
							disabled={authenticating}
							as={Button}
							variant="primary"
							onClick={handleLogin}
							type="submit"
							style={{
								backgroundColor: '#CE0058',
								borderColor: '#AB0047',
							}}
							isInvalid={invalid}
							block
						>
							{!authenticating ? (
								'Sign In'
							) : (
								<>
									Signing In...
									<Spinner
										style={{ marginLeft: '10px' }}
										as="span"
										animation="grow"
										size="sm"
										role="status"
										aria-hidden="true"
									/>{' '}
								</>
							)}
						</Form.Control>
						<Form.Control.Feedback type="invalid">
							<div className="centered-feedback">
								Incorrect login information. Please verify API
								Credentials.
							</div>
						</Form.Control.Feedback>
					</Form.Group>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							marginTop: '2em',
						}}
					>
						<a
							style={{
								alignItems: 'center',
								color: '#212529',
								display: 'flex',
							}}
							href="https://app.uberflip.com/account/api_v2"
							rel="noopener noreferrer"
							target="_blank"
						>
							View REST API Credentials
							<OpenInNewIcon
								style={{ marginLeft: '0.25em' }}
								fontSize="small"
							/>
						</a>
					</div>
				</Form>
			</div>
		</>
	);
}
